import styled, {css} from "styled-components";
import { ModalTitle, Tabs, Modal } from 'react-bootstrap';
import { ImageInput } from '../CreateEvent/styles';
const BoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Regular';
const MediumFont = 'Rubik-Medium';

const StyledTabContainer = styled(Tabs)`
   float: left;
   width: 100%;
  > ul {
    padding: 20px 24px 1px 18px;
    font-size: 12px;
    text-transform: uppercase;
    color: rgb(153,153,153);
    background: ${({bgColor}) => bgColor ? bgColor :'#fff'};
    border-radius: 6px;
    margin: 0 0px 0 0px;
    margin-bottom: 25px;
    
    > li {
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: ${BoldFont};
        margin-right: 5vw;
        text-transform: uppercase;
        padding: 5px 5px 17px 5px;
        color: rgb(156,156,156);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-bottom: none;
        }
      }
    }
    
    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: rgb(21,159,202);
        border: none;
        border-bottom: 3px solid rgb(21,159,202);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid rgb(21,159,202);
          color: rgb(21,159,202);
        }
      }
    }
  }
`;

const SocialFeedsContainer = styled.div`
  float: left;
  width: ${({webinar}) => webinar ? '100%' : '67.1%'};
  margin-right: ${({videoCSS}) => videoCSS ? '0px': '35px'};
  
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const ScrollableFeedsContainer = styled.div`
  width: 100%;
  float: left;
  height: 1600px;
  overflow-x: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 7px;
    height: 5px;
    
    @media (max-width: 500px) {
      width: 5px;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: #c7c8c7;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }
`;

const InputFieldContainer = styled.div`
  width: ${({UpdateStatus, survey}) => UpdateStatus || survey ? '100%' : 'calc(100% - 70px)'};
  float: left;
  background-color: white;
  -moz-box-shadow: ${({UpdateStatus}) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none' };
  -webkit-box-shadow: ${({UpdateStatus}) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none' };
  box-shadow: ${({UpdateStatus}) => UpdateStatus ? '1px 1.732px 46px rgba(0,0,0,0.1)' : 'none' };
  height: 'auto';
  border-color: ${({comment, survey}) => comment ? 'rgba(0, 0, 0, 0.09)' : survey ? '#cdcbcb': 'none'};
  border-image: ${({comment}) => comment ? 'initial' : 'none'};
  padding: ${({comment}) => comment ? '20px' : '15px'};
  border-radius: ${({comment}) => comment ? '8px' : '4px'};
  border-width: ${({comment, survey}) => comment || survey ? '1px' : '0px'};
  border-style: ${({comment, survey}) => comment || survey ? 'solid' : 'none'};
  @media (max-width: 500px) {
    width: ${({UpdateStatus}) => UpdateStatus ? '100%' : 'calc(100% - 50px)'};
  }
  .CustomTextAreaField {
    line-height: 18px;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    width: calc(100% - 25px);
    color: #005c87;
    resize: none;
    float: left;
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: transparent;
    overflow: auto;
    transition: transform 1s;
    padding: 0;
    letter-spacing: 1px;
    
    &:focus, &:active {
      outline: none;
    }
      
    ::placeholder {
      color: rgb(153,153,153);
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: rgb(153,153,153);
    }

    ::-ms-input-placeholder {
      color: rgb(153,153,153);
    }
  }
`;

const InputTextArea = styled.div`
  position: relative;
  height: ${({isCommentOrReply}) => isCommentOrReply ? '30px' : '100px'};
  #textArea{
  ::placeholder {
    color: #649bb3;
  }
}
`;

const ShowTaggedUser = styled.div`
  margin-bottom: 5px;
  display: inline-block;
  border-radius: 5px;
  background: #ffffff;;
  cursor: default;
  border: solid 1px #dadada;
  float: left;
  padding: 2px 10px;

  font-family: Rubik-Regular;
  color: #423d3d;
  letter-spacing: 1px;
  text-transform: capitalize;
`;
const ShoutImage = styled.img`
  margin-left: 5px;
  max-width: 20px;
  max-height: 20px;
`;

const Cross = styled.img`
  margin-left: 5px;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;

const UserContainer = styled.div`
  width: 90%;
  position: absolute;
  height: auto;
  border-radius: 6px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px;
  z-index: 2;
  
  display: ${({showSuggestor}) => showSuggestor ? "block" : "none"};
  top: ${({top}) => top};
  left: ${({left}) => left};
`;

const UserDropdownContainer = styled.div`
  background: ${({index, currentSelection}) => index === currentSelection ? '#2595cc' : ''};
  : hover {
    cursor: pointer;
  }
`;

const ListDropdown = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  word-spacing: normal;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  font-weight: ${({index, currentSelection}) => index === currentSelection ? 'bold' : 'normal'};
  color: ${({index, currentSelection}) => index === currentSelection ? 'white' : 'black'};
`;
const ProfilePicture = styled.div`
  width: 100%;
  display: inline-block;
`;

const CustomTextAreaField = styled.textarea`
  font-size: 14px;
  border-radius: 4px;
  border: none;
  width: calc(100% - 25px);
  color: rgb(153,153,153);
  resize: none;
  float: left;
  height: 100%;
  
  &:focus, &:active {
    outline: none;
  }
    
  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const ContentContainer = styled.div`
  float: left;
  width: 100%;
`;

const InputImage = styled(ImageInput)`
  padding: 0;
  background-color: white;
  border-radius: 0;
  float: right;
  margin-bottom: 0;
  margin-top: ${({noMarginTop}) => noMarginTop ? '5px' : '55px' }};
  > i {
    color: #dddddd;
    font-size: 25px;
  }
`;

const IconContainer = styled.div`
  display: ${({cameraIcon}) => cameraIcon ? 'contents' : 'block'}
  width: 100%;
  height: auto;
  float: inherit;
`;

const ShoutoutDiv = styled.div`
  display: flex;
`;

const ShoutoutContainer = styled.div`
  width: ${({width}) => width ? width : '135px'};
  font-family: Rubik-Regular;
  margin-right: 5px;
  margin-top: 5px
  float: left;
  padding: ${({height}) => height ? '3px 15px' : '2px 15px'};
	border-radius: 18px;
	height: ${({height}) => height}
	border: 1px solid #dddddd;
	cursor: pointer;
  color: #423d3d;
	background: #e6e6e6;
	display: ${({display}) => display === 1 ? 'flex' : 'block'};
	justify-content: ${({display}) => display === 1 ? 'center' : 'none'};
    
  > img {
    vertical-align: text-bottom;
		padding-right: 10px;
		width: 30px;
  }
`;

const FeedsContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: 15px;
  background-color: white;
  -moz-box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);
  -webkit-box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.1);

  &:first-child {
    margin-top: 0;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  float: left;
  padding: ${({ onModal }) => onModal ? '5px 15px' : '25px 25px 0 30px'};

  > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    float: left;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 767px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  @media (max-width: 500px) {
    padding: ${({ onModal }) => onModal ? '0' : '25px 15px 0 15px'};
  }
`;

const ImageWrapper = styled.div`
  width: ${({ isCommentSection }) => isCommentSection ? '60px' : '60px'};
  height: ${({ isCommentSection }) => isCommentSection ? '60px' : '60px'};
  border-radius: 50%;
  float: left;
  cursor: pointer;
  margin-right: ${({ isCommentSection }) => isCommentSection ? '20px' : '0'};

  > img {
    width: 60px;
    height: 60px;
    border-radius: ${({ borderRadius }) => borderRadius ? borderRadius : '50%'}
    float: left;
    cursor: pointer;
    object-fit: cover;
  }

  ${({ isCommentSection }) => isCommentSection && css`
    > img {
      width: 60px;
      height: 60px;
    }
  `}

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    > img {
      width: 40px;
      height: 40px;
    }
  }
`;

const UserInformation = styled.div`
  float: left;
  display: inline-block;
  margin-left: 10px;
  line-height: 25px;
  text-transform: capitalize;
  height: 60px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: ${({width}) => width ? 'calc(100% - 130px)': 'calc(100% - 70px)'};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: flex-start;
  > span {
    font-size: 18px;
    letter-spacing: 0px;
    color: #3a3a3a;
    font-family: ${BoldFont};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  
  > div {
    font-size: 12px;
    letter-spacing: 0px;
    color: rgb(153,153,153);
    
    > i {
      margin-right: 5px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    margin-right: 10px;
    width: ${({width}) => width ? 'calc(100% - 130px)': 'calc(100% - 70px)'};
  }

  ${({ isOwnPost }) => isOwnPost && css`
    width: calc(100% - 110px);
  `}
`;

const Description = styled.div`
  width: ${({width}) => width ? 'auto' : '100%'};
  display: ${({contents}) => contents ? 'block' : 'block'}
  font-size: 16px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  margin-top: 20px;
  white-space: pre-line;

  @media (max-width: 500px) {
    padding: 0 0px;
  };

  span {
    letter-spacing: 0px;
    color: rgb(21,159,202);
    font-family: Rubik-Bold;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
    text-transform: capitalize;
  }
`;

const FeedInformation = styled.div`
  width: 100%;
  float: left;
  border-bottom: 1px solid #e2e2e4;
  padding: 0 45px 25px 35px;

  @media (max-width: 500px) {
    padding: 0 0 25px;
    > img {
      width: 100%;
    }
  }
`;

const SingleFeedDescription = styled.div`
  margin-top: 35px;
  @media (max-width: 500px) {
    padding: 0 15px;
  };
`;

const ShoutoutImage = styled.div`
  display: inline;
  > img {
    width: ${({comment}) => comment ? '16px' : '24px'};
    height: ${({comment}) => comment ? '16px' : '24px'};
    margin-left: 7px;
  }
`;

const FeedFeatures = styled.div`
  float: left;
  width: 100%;
  margin-top: 15px;
  
  > div {
    float: left;
    line-height: 36px;
  }
  
  > button {
    float: right;
  }

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media (max-width: 500px) {
    padding: 0 15px;
  }
`;

const CommentCounts = styled.button`
  display: inline-block;
  float: left;
  margin-left: 18px;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: white;

  > i {
    float: left;
    color: #bbbbbb;
    font-size: 16px;
    margin-right: 6px;
  }
  
  > span {
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 16px;
    color: rgb(51,51,51);
    font-family: ${BoldFont};
    float: left;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-top: 0;
    margin-right: 6px;
  }

  &:active, &:focus {
    outline: none;
  }
`;

const LikeCounts = styled(CommentCounts)`
  margin-left: 0;
  > i {
    color: #bbbbbb;
  }
  ${({ active }) => active && css`
    > i {
      color: #ef6827;
    }
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed !important;
    opacity: 0.5;
    background-color: white;
  `}
`;

const CommentButton = styled.button`
  background-color: #159fc9;
  border: 1px solid #159fc9;
  color: white;
  white-space: nowrap;
  padding: 8px 20px;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: uppercase;
  font-family: ${BoldFont};

  ${({ right }) => right && css`
    float: right;
    margin-top: 15px;
  `}
  
  &:focus, &:active, &:hover {
    outline: none;
  }

  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
`;

const StyledTitle = styled(ModalTitle)`
  font-size: 18px !important;
  letter-spacing: 0px !important;
  color: white !important;
  font-family: ${RegularFont} !important;
  text-align: center !important;
`;

const StyledInput = styled.input`
  width: calc(100% - 52px);
  float: right;
  font-size: 14px;
  padding: 12px 10px;
  border-radius: 4px;
  border: none;
  color: rgb(153,153,153);
  
  &:focus, &:active {
    outline: none;
  }
      
  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const ImageContainer = styled.div`
  padding: 15px;
  width: 100%;
  float: left;
  
  > img {
    width: 100%;
    height: ${({editPost}) => editPost && '264px'};
  }

  @media (max-width: 500px) {
    padding: 15px 0;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  margin: 20px 0;
`;

const SideBarContainer = styled.div`
  width: ${({videoCSS}) => videoCSS ? '32.9%': 'calc(35% - 35px)'};
  float: left;
  padding-left: 25px;
  .wellnessInitiatives {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 21px rgba(0,0,0,0.08);
    float: left;
    margin-bottom: 20px;
  }
  
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-left: ${({videoCSS}) => videoCSS && '10px'}
  }
  .biometric-label {
    font-size: 12px;
    letter-spacing: 0px;
    color: rgb(255,255,255);
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%; 
  }
`;

const TileContainer = styled.div`
  width: 100%;
  float: left;
  background-color: white;
  padding: ${({ padding }) => padding ? '0px' : '15px'};
  margin-top: ${({ profilePage }) => profilePage ? '0' : '30px'};
  box-shadow: ${({ padding }) => padding ? 'none' : '1px 1.732px 46px rgba(0,0,0,0.1)'};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom };
  border-radius: ${({ padding }) => padding && '6px'};
  position: ${({ padding }) => padding && 'relative'};
  cursor: ${({ padding }) => padding && 'pointer'};
  &:first-child {
    margin-top: 0;
  }
  .bg-opacity {
    position: absolute;
    height: 225px;
    width: 100%;
    top: 0;
    left: 0;
    background: #000000;
    opacity: 0.3;
    border-radius: 6px;
  }
  .image{
    width: 41px;
    height: 41px;
    position: absolute;
    bottom: 65px;
    left: 25px;
  }
  .featured-text{
    font-family: ${MediumFont};
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    bottom: 85px;
    left: 68px;
  }
  @media (max-width: 1235px){
    width: ${({width}) => width ? '49%' : '100%'}
  }
  @media (max-width: 500px){
    width: 100%;
  }
`;

const TitleContent = styled.div`
  font-size: 14px;
  border-bottom: 1px solid #e2e2e4;
  width: 100%;
  float: left;
  padding: 0 10px 10px 5px;
  letter-spacing: 0px;
  line-height: 28px;
  cursor: ${({ isClickable }) => isClickable ? 'pointer' : ''};
  
  > i {
    color: #159fc9;
    font-size: 13px;
  }
  > span {
    margin-left: 8px;
  }
  
  span:nth-child(3) {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 28px;
    float: right;
    color: rgb(153,153,153);
    
    > span {
      font-family: ${BoldFont};
      color: rgb(21,159,202);
    }
  }
  
  > svg {
    width: 20px;
    height: 24px;
    padding-top: 4px;
  }
`;

const SideBarContent = styled.div`
  padding: 15px 10px 5px 5px;
  float: left;
  width: 100%;
  .inline {
    float: left;
    height: 100%;
    width: 100%;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
  > span {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 20px;
    color: rgb(153,153,153);
  }

  .image-container {
    float: left;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }

    > img {
      float: left;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      cursor: ${({isClickable}) => isClickable && 'pointer'}
    }
  }
  
  ${(props) => props.notificationSection && css`
    border-bottom: 1px solid #dededf;
    padding: 15px 10px 15px 5px;
    height: 100%;
  `}
`;

const StyledImage = styled.div`
  float: left;
  height: 100%;
  display: table;
  width: ${({width}) => width};

  .middle {
    height: 100%;
    display: ${({left}) => left ? 'inline-block' : 'table-cell'};
    vertical-align: middle;
    
    img {
      width: 45px;
      height: ${({height}) => height ? height : '45px'};
      border-radius: 50%;
      float: left;
      margin-right: 10px;
    }
  }
`;

const DropDownStyledImage = styled.div`
  float: left;
  height: 100%;
  display: table;
  width: ${({width}) => width};
  .middle {
    height: 100%;
    display: ${({left}) => left ? 'inline-block' : 'table-cell'};
		vertical-align: middle;
		min-width: 50px;
		min-height: 40px;
    > img {
      border-radius: 50%;
      width: 30px;
      height: ${({height}) => height ? height : '45px'};
      float: left;
      margin: 5px 10px;
    }
  }
`;

const NotificationDescription = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  word-spacing: normal;
  display: table-cell;
  vertical-align: middle;
  .lowerCase {
    text-transform: lowercase;
    color: rgb(153,153,153);
  }
`;

const WellnessExpertImage = styled.img`
  float: left;
  width: 100%;
  cursor: pointer;
  border-radius: 6px;
  height: 225px;
`;

const WellnessExpertName = styled.div`
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 28px;
  color: rgb(51,51,51);
  font-family: ${MediumFont};
  float: left;
  width: 100%;
  text-align: left;
  padding: 15px 0 15px 35px;
  cursor: pointer;
`;

const FeaturedEventsContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: ${({ noMargin }) => noMargin ? '0' : '20px'};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
`;

const DownAngle = styled.i`
  float: right;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 45px;
  cursor: pointer;

  .fas {
    margin-right: 4px;
    color: rgb(153,153,153);
  }
  .fa {
    margin-right: 4px;
    color: rgb(153,153,153);
  }  
  @media only screen and (max-width: 767px) {
    position: relative;
    right: 0px;
    margin-left: 38px;
    .btn {
      padding: 0px;
    }
  }
  .dropdown-toggle {
    border: none !important;
    background-color: white !important;
    box-shadow: none !important;
    .caret {
      display: none;
    }
    > i {
      color: rgb(153,153,153);
    }
  }   
  .dropdown-menu {
    margin-left: -74px;
    width: 98px;
    min-width: 0px !important;

    margin-top: -10px;
    @media(max-width: 600px) {
      margin-top: -7px;
      margin-left: -86px;
    }
    > li {
      > a {
        color: rgb(153,153,153);
        font-family: ${BoldFont};
      }
    }
  }
`;

const NoComments = styled.div`
  float: left;
  width: 100%;
  padding: ${({ value }) => value ? '0 0 25px 0' : '30px'};
  font-size: ${({ value }) => value ? '18px' : '15px'};
  font-family: ${BoldFont};
  
  padding: ${ ({isEventChatComment}) => isEventChatComment && '30px'};
  font-size: ${ ({isEventChatComment}) => isEventChatComment && '16px'};
  color: ${ ({isEventChatComment}) => isEventChatComment && 'rgb(0, 92, 135)'};
  font-family: ${ ({isEventChatComment}) => isEventChatComment && 'Rubik-Regular'};
  font-style: ${ ({isEventChatComment}) => isEventChatComment && 'normal'};
  font-weight: ${ ({isEventChatComment}) => isEventChatComment && '400'};
  line-height: ${ ({isEventChatComment}) => isEventChatComment && '20px'};
`;

const NoActivityFeeds = styled.p`
  font-size: 14px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgb(153,153,153);
  float: left;
  width: 100%;
  padding: 15px 0 0 5px;
`;

const Layout = styled.div`
  padding: 47px 35px 20px 30px;;
  width: 100%;
  float: left;
  min-height: 650px;
  font-family: ${RegularFont};
  display: flex;
  @media only screen and (max-width: 767px) {
    padding: 45px 15px 20px;
  }
`;

const Profile = styled.div`
  display: inline-block;
  width: 50%;
  text-align: left;
  vertical-align: middle;

  &:last-child {
    text-align: right;
  }

  .flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    > img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
  
      @media (max-width: 500px) {
        width: 40px;
        height: 40px;
      }
    }
    > span {
      text-transform: capitalize;
      font-size: 16px;
      font-family: ${BoldFont};
      margin-left: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
  
      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
  }

  > button {
    height: 25px;
    width: 70px;
    border-radius: 4px;
    background-color: #159fc9;
    border: none;
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-family: ${BoldFont};
    margin-left: 10px;

    &:last-child {
      border: 1px solid gray;
      color: gray;
      background-color: white;
    }

    @media (max-width: 500px) {
      width: 50px;
      font-size: 10px;
      padding: 0;
    }

    &:focus, &:active {
      outline: none;
    }
  }

  @media (max-width: 500px) {

    > span {
      width: calc(100% - 65px);
      text-align: left;
    }

    > button {
      &:last-child {
        margin-top: 10px;
      }
    }
  }
`;

const NoFriendRequests = styled.p`
  font-size: 14px;
  -webkit-letter-spacing: 0px;
  -moz-letter-spacing: 0px;
  -ms-letter-spacing: 0px;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgb(153,153,153);
  margin-bottom: 0;
`;

const CommentSection = styled.div`
  width: 100%;
  float: left;
  margin-bottom: 35px;
`;

const ProfileContainer = styled.div`
  padding: 5px 60px;

  @media (max-width: 500px) {
    padding: 5px 0;
  }
`;

const AssessmentContainer = styled.div`
	background: linear-gradient(180deg, #37ED7D 0%, #129A8E 100%);
  width: 100%;
  font-family: ${BoldFont};
  display: block;
  color: white;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const TipInformation = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  padding: 23px 20px;
  cursor: pointer;
  > span {
    font-size: 12px;
    letter-spacing: 0px;
    color: rgb(255,255,255);
    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
`;

const InformationIcon = styled.div`
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: transparent;
  text-align: center;
  display: inline-block;
  margin-right: 9px;
  font-family: ${BoldFont};
`;

const WrapImage = styled.div`
  width: 100%;
  margin-top: 20px;
  float: left;
            
  > img {
    width: auto;
    max-width: 100%;
    float: left;
    
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

const StyledModal = styled(Modal)`
	.modal-dialog{
		display: flex;
    justify-content: center;
    transform: ${({transform}) => transform && 'translate(0, 50vh) translate(0, -50%) !important'};
		.modal-content{
			width: 600px;
      border-radius: 10px;
      border: none;
			.modal-header {
				border-bottom: ${({noBorder}) => noBorder ? noBorder : '1px solid #e5e5e5'};
        background: linear-gradient( 
          180deg,#52A8ED 0%,#8AD3F7 100%);

				> div {
					display: flex;
					justify-content: center;

					> p{
						font-size: 20px;
						font-family: ${BoldFont};
						margin-top: 10px 0 0 0;
					}
				}
			}
			.modal-header .close {
				outline: none;
        float: right;
        margin-top: -21px;
        margin-right: 2px;
       color: white;
       font-size: 44px;
        opacity: 0.9;
			}
		}
	}
  @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
			width: 100%;

			.modal-content{
				width: 90%;
				margin-left: -6%;
			}
    }
  }
`;

const EmojiContainer = styled.div`
padding: 10px;
width: 100%;
display: ${({length}) => length === 0 ? 'flex' : 'grid'};
gap: 10px;
grid-template-columns: repeat(2, 35%);
grid-template-rows: repeat(${({length}) => length ? length : '3'}, 55px);
grid-auto-flow: row;
font-family: ${({length}) => length === 0 ? `${BoldFont}` : ` ${RegularFont}`};
color: ${({length}) => length === 0 ? '#a8a5a5' : 'none'};
font-size: ${({length}) => length === 0 ? '16px' : '14px'};
justify-content: ${({length}) => length === 0 ? 'center' : 'space-evenly'};
> div {
    font-family: ${RegularFont};
    cursor: pointer;
    float: left;
    display: grid;
    grid-template-columns: 20% 90%;
    gap: 10%;
    height: 50px;
		padding: 5px 5px 5px 10px;
		border-radius: 5px;
		box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
	}

	@media (max-width: 500px) and (min-width: 320px) {
		grid-template-columns: repeat(2, 55%);
		> div {
			width: 90%;
			margin-left: 10px;
			padding: 7px 5px 5px 5px;
			gap: 7%;
			grid-template-columns: 30% 90%;
		}
	}

`;

const Text = styled.p`
	margin: 10px 0 0px;
	font-family: ${RegularFont};

	@media (max-width: 500px) and (min-width: 320px) {
		font-size: 11px;
	}
`;
const ImageWrap = styled.div`
width: 40px;
height: 40px;
border-radius: 100%;
display: flex;
justify-content: center;
	> img {
		width: 50%;
    height: 50%;
    margin-top: 10px;
	}

	@media (max-width: 500px) and (min-width: 320px) {
		width: 35px;
    height: 35px;
	}
`;

const StyledTitleV2 = styled(ModalTitle)`
  font-size: 18px !important;
  letter-spacing: 0px;
  color: white !important;
  font-family: "Rubik-Medium" !important;
  text-align: center !important;
  height:24px !important;
  font-weight: 500 !important;
  line-height: 24px !important;  
  width: 100% !important;
`;

const IconContainerNew = styled.div`
width: unset !important;
position:absolute;
right: 4px;
`;

const InputImageNew = styled(ImageInput)`
  padding: 8px 12px;
  background-color: white;
  border-radius: 0px;
  float: right;
  margin-bottom: -4px;
  margin-top: 0px
  > i {
    color: #dddddd;
    font-size: 25px;
  }
`;

export { ShoutoutImage, SingleFeedDescription, ShoutoutDiv, ShoutImage, Cross, ShowTaggedUser, DropDownStyledImage, InputTextArea, UserContainer, UserDropdownContainer, ListDropdown, ProfilePicture, IconContainer, ShoutoutContainer ,SocialFeedsContainer, CustomTextAreaField, ContentContainer, InputFieldContainer, InputImage, FeedsContainer, HeaderContainer, UserInformation,
  Description, FeedInformation, FeedFeatures, CommentCounts, LikeCounts, CommentButton, StyledTabContainer, StyledTitle, StyledInput, ImageContainer,
  ButtonContainer, SideBarContainer, TileContainer, TitleContent, SideBarContent, StyledImage, NotificationDescription, WellnessExpertImage,
  WellnessExpertName, FeaturedEventsContainer, DownAngle, NoComments, Layout, Profile, NoFriendRequests, NoActivityFeeds,
  CommentSection, ScrollableFeedsContainer, AssessmentContainer, TipInformation, InformationIcon, ProfileContainer, ImageWrapper, WrapImage,
  StyledModal, EmojiContainer, ImageWrap, Text, StyledTitleV2, IconContainerNew, InputImageNew

};
